// mask-validation.service.ts

import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import { dockerEnvironment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MaskValidationService {


  private apiBaseUrl = dockerEnvironment.apiUrl

  constructor(private http: HttpClient) {

  }

  formatCPF(value: string): string {
    // Remove todos os caracteres não numéricos
    const cleanedValue = value?.replace(/\D/g, '');

    // Limita o tamanho máximo do CPF para 11 dígitos
    const maxLength = 11;
    const formattedValue = cleanedValue.slice(0, maxLength);

    // Aplica a máscara do CPF
    if (formattedValue.length < 4) {
      return formattedValue;
    } else if (formattedValue.length < 7) {
      return `${formattedValue.slice(0, 3)}.${formattedValue.slice(3)}`;
    } else if (formattedValue.length < 10) {
      return `${formattedValue.slice(0, 3)}.${formattedValue.slice(3, 6)}.${formattedValue.slice(6)}`;
    } else {
      return `${formattedValue.slice(0, 3)}.${formattedValue.slice(3, 6)}.${formattedValue.slice(6, 9)}-${formattedValue.slice(9)}`;
    }
  }

  formatCNPJ(value: string): string {
    // Remove todos os caracteres não numéricos
    const cleanedValue = value?.replace(/\D/g, '');

    // Limita o tamanho máximo do CNPJ para 14 dígitos
    const maxLength = 14;
    const formattedValue = cleanedValue.slice(0, maxLength);

    // Aplica a máscara do CNPJ
    if (formattedValue.length < 3) {
      return formattedValue;
    } else if (formattedValue.length < 6) {
      return `${formattedValue.slice(0, 2)}.${formattedValue.slice(2)}`;
    } else if (formattedValue.length < 9) {
      return `${formattedValue.slice(0, 2)}.${formattedValue.slice(2, 5)}.${formattedValue.slice(5)}`;
    } else if (formattedValue.length < 13) {
      return `${formattedValue.slice(0, 2)}.${formattedValue.slice(2, 5)}.${formattedValue.slice(5, 8)}/${formattedValue.slice(8)}`;
    } else {
      return `${formattedValue.slice(0, 2)}.${formattedValue.slice(2, 5)}.${formattedValue.slice(5, 8)}/${formattedValue.slice(8, 12)}-${formattedValue.slice(12)}`;
    }
  }


  validateEmailFormat(control: any, value?: any, valueEmailInput?: any, valueEmailAtual?: any): Promise<void> {
    return new Promise((resolve, reject) => {
      const email = control?.value;
      const emailFormatError = { emailFormat: true };
      const emailInUseError = { emailInUse: true };
      const isEmailInvalid = email && !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/.test(email);

      if (value && valueEmailInput) {
        if (isEmailInvalid || email.length < 11) {
          control.setErrors(emailFormatError);
          resolve();
        } else if(valueEmailInput != valueEmailAtual) {
          this.validateEmailApi(value, valueEmailInput).subscribe(
            () => {
              control?.setErrors(null);
              resolve();
            },
            () => {
              control?.setErrors(emailInUseError);
              resolve(); // Consider resolve even on error for the sake of the flow
            }
          );
        }
      } else {
        if (isEmailInvalid) {
          control.setErrors(emailFormatError);
          resolve();
        }
        else {
          control?.setErrors(null);
          resolve();
        }
      }
    });
  }


  validateEmailFormatLocal(control: AbstractControl) {
    const email = control.value;

    if (email && !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/.test(email)) {
      return control.setErrors({emailFormat: true})
    }

    return null
  }

  formatContaCorrente(value: string): string {
    // Remove todos os caracteres não numéricos
    const cleanedValue = value?.replace(/\D/g, '');

    // Limita o tamanho máximo da conta corrente para 7 dígitos (8 dígitos menos o último)
    const maxLength = 20;
    const formattedValue = cleanedValue.slice(0, maxLength);

    // Aplica a máscara da conta corrente
    if (formattedValue.length < 1) {
      return formattedValue;
    } else {
      const lastDigit = formattedValue.slice(-1); // Pegar o último dígito
      const rest = formattedValue.slice(0, -1); // Remover o último dígito
      return `${rest}-${lastDigit}`;
    }
  }


  formatSEI(value: string): string {
    // Remove todos os caracteres não numéricos
    const cleanedValue = value?.replace(/\D/g, '');

    // Limita o tamanho máximo para 21 dígitos
    const maxLength = 21;
    const formattedValue = cleanedValue.slice(0, maxLength);

    // Aplica a máscara customizada
    if (formattedValue.length < 5) {
      return formattedValue;
    } else if (formattedValue.length < 11) {
      return `${formattedValue.slice(0, 4)}.${formattedValue.slice(4)}`;
    } else if (formattedValue.length < 16) {
      return `${formattedValue.slice(0, 4)}.${formattedValue.slice(4, 10)}.${formattedValue.slice(10)}`;
    } else if (formattedValue.length < 21) {
      return `${formattedValue.slice(0, 4)}.${formattedValue.slice(4, 10)}.${formattedValue.slice(10, 15)}/${formattedValue.slice(15)}`;
    } else {
      return `${formattedValue.slice(0, 4)}.${formattedValue.slice(4, 10)}.${formattedValue.slice(10, 15)}/${formattedValue.slice(15, 19)}-${formattedValue.slice(19)}`;
    }
  }

  validateCpf(control: any) {
    const cpfValue = control.value;
    if (!cpf.isValid(cpfValue)) {

      return { cpfFormat: false }
    }

    else {
      return null
    }

  }

  validateCnpj(control: any) {
    const cnpjValue = control.value

    if (!cnpj.isValid(cnpjValue)) {
      return { cnpjFormat: false }
    }
    else {
      return null
    }
  }


  formatPhoneNumber(value: any): string {
    // Remove todos os caracteres não numéricos
    const cleanedValue = value?.replace(/\D/g, '');
    if (!cleanedValue) {
      return ''
    }

    // Verifica se há números suficientes para aplicar a máscara
    if (cleanedValue.length < 2) {
      // Se não houver números suficientes, retorna o valor original
      return cleanedValue;
    } else if (cleanedValue.length < 7) {
      // Se há menos de 7 dígitos, aplica a máscara parcial
      return `(${cleanedValue.substring(0, 2)}) ${cleanedValue.substring(2)}`;
    } else {
      // Aplica a máscara completa
      return `(${cleanedValue.substring(0, 2)}) ${cleanedValue.substring(2, 7)}-${cleanedValue.substring(7)}`;
    }
  }

  formatCEP(value: string): string {
    // Remove todos os caracteres não numéricos
    const cleanedValue = value?.replace(/\D/g, '');

    // Limita o tamanho máximo do CEP para 8 dígitos
    const maxLength = 8;
    const formattedValue = cleanedValue.slice(0, maxLength);

    // Aplica a máscara do CEP
    if (formattedValue.length < 6) {
      return formattedValue;
    } else {
      return `${formattedValue.slice(0, 5)}-${formattedValue.slice(5)}`;
    }
  }

  noSpaceValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value && control.value.trim().length === 0) {
        return { 'noSpace': true };
      }
      return null;
    };
  }

  noLeadingSpaceValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value && control.value.startsWith(' ')) {
        return { 'noLeadingSpace': true };
      }
      return null;
    };
  }


  formatCurrency(value: number): string {

    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
    });
    return formatter.format(value);
  }

  formatPercent(value: string): string {

    if (typeof value !== 'string') {
      value = String(value);
    }
    let valueFormatted = value.replace(/[^\d.]/g, '');
    let valueNewFormated = valueFormatted.replace(/(\..*?)\..*/g, '$1');
    return valueNewFormated + '%';
  }


  validateCpforCnpjApi(value: any) {
    return this.http.get<any>(`${this.apiBaseUrl}/v1/auth/validate/${value}`)
  }

  validateEmailApi(value: any, valueEmail: any) {
    return this.http.get<any>(`${this.apiBaseUrl}/v1/auth/validate/${value}/${valueEmail}`)
  }

  validateDirfApi(id: string, ano: string) {
    return this.http.get<any>(`${this.apiBaseUrl}/v1/user/report/user-permisson?id=${id}&ano=${ano}`)
  }
}
